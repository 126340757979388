import { memo } from 'react';
import cn from 'classnames';

import { Link } from '../../../ui';
import logo from '../../../assets/logo_b.svg';
import { LogoProps } from './types';
import classes from './Logo.module.scss';

export const Logo = memo(({ className }: LogoProps) => (
  <Link href="https://superprotocol.com">
    <img src={logo} alt="" className={cn(classes.logo, className)} />
  </Link>
));
