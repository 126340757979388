import { Link } from '../../ui';
import {
  title, lines, links, text,
} from './helpers';
import classes from './Content.module.scss';

export const Content = () => {
  return (
    <section className={classes.content}>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.lines}>
        {lines.map((item, idx) => (
          <p key={idx} className={classes.line}>{item}</p>
        ))}
        <div className={classes.linkLine}>
          <Link href={links[0].href} className={classes.link}>
            {links[0].text}
          </Link>
          &nbsp;
          <span className={classes.text}>{text}</span>
          &nbsp;
          <Link href={links[1].href} className={classes.link}>
            {links[1].text}
          </Link>
        </div>
      </div>
    </section>
  );
};
