export const title = 'This Super protocol testnet site has expired';

export const lines = [
  'To optimize testnet performance we limited uptime to 72 hours and domains are deleted after 30 days',
  'Let’s make it go live again!',
];

export const links = [
  {
    href: 'https://marketplace.superprotocol.com',
    text: 'Create new order',
  },
  {
    href: 'https://docs.superprotocol.com/testnet',
    text: 'Sign up for testnet',
  },
];

export const text = 'if you are already a participant or';
