import { text } from './helpers';

import classes from './Footer.module.scss';

export const Footer = () => (
  <footer className={classes.footer}>
    <div className={classes.text}>
      {text}
    </div>
  </footer>
);
